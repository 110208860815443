var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-neutral-50"},[(_vm.isLoading)?_c('div',{staticClass:"p-10 bg-neutral-50"},[_c('brightbid-loader',{staticClass:"mt-64",attrs:{"size":"100"}})],1):_c('div',{staticClass:"main-container base-scrollbar"},[_c('div',{staticClass:"mb-20 w-full"},[_c('div',{staticClass:"px-6 pt-6"},[_c('div',{staticClass:"bg-white py-6 px-4 rounded-lg border-neutral-100 border md:flex justify-between"},[_c('div',{staticClass:"md:inline-flex gap-3 items-center pt-1"},[_c('div',{staticClass:"text-gray-700 text-sm font-medium"},[_vm._v("Show:")]),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"min-w-xxs p-1 bg-neutral-50 rounded-full my-auto justify-start items-center inline-flex"},[_c('div',{class:{
                    'w-1/2 py-1 rounded-full justify-center items-center gap-3 flex cursor-pointer': true,
                    'text-bb-text-secondary font-bold ': _vm.portfolioViewMode !== 'site',
                    'text-bb-brand-purple bg-white': _vm.portfolioViewMode == 'site',
                  },on:{"click":function($event){return _vm.togglePortfolioViewMode('site')}}},[_c('div',{staticClass:"p2 font-bold"},[_vm._v("Sites")])]),_c('div',{class:{
                    'w-1/2 py-1 rounded-full justify-center items-center gap-3 flex cursor-pointer': true,
                    'text-bb-text-secondary ': _vm.portfolioViewMode !== 'organization',
                    'text-bb-brand-purple  bg-white': _vm.portfolioViewMode == 'organization',
                  },on:{"click":function($event){return _vm.togglePortfolioViewMode('organization')}}},[_c('div',{staticClass:"p2 font-bold"},[_vm._v("Organizations")])])])])]),(_vm.portfolioViewMode === 'organization')?_c('text-input',{staticClass:"max-w-64 w-full ml-auto",attrs:{"has-icon":"","disabled":_vm.isLoading,"input-name":"search-query","input-id":"search-query","input-type":"text","placeholder":"Search organization...","padding-left":"pl-10"},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('div',{staticClass:"absolute z-10 h-10 p-2 text-bb-disabled-gray"},[_c('IcSearch')],1)]},proxy:true}],null,false,511922060),model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}):_vm._e()],1),_c('div',{staticClass:"mt-3"},[(_vm.portfolioViewMode === 'organization')?_c('div',[_c('p',{staticClass:"h4"},[_vm._v("All Organizations")]),_vm._l((_vm.filteredPortfolioData),function(item){return _c('div',{key:item.id,staticClass:"cursor-pointer"},[_c('PortfolioCard',{attrs:{"keep-alive":"","organization":item}})],1)}),(_vm.filteredPortfolioData.length == 0)?_c('div',[_vm._v("No organizations found")]):_vm._e()],2):_c('div',[_c('p',{staticClass:"h4"},[_vm._v("All Sites")]),_vm._l((_vm.sitesData),function(site){return _c('div',{key:site.id},[_c('bbSiteCard',{attrs:{"site":site}})],1)})],2)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }