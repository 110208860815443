<template>
  <bbBaseCard
    class="bg-white mt-4"
    :checkbox="false"
    :curtain="true"
    @toggleExpansion="loadMetrics(organization.id)"
    @cardClick="cardClicked(organization.id)"
  >
    <template #first>
      <div class="flex flex-col">
        <p class="text-bb-text-secondary">Organization</p>
        <div class="flex flex-row gap-2 z-50 mt-3">
          <div
            class="my-auto z-50"
            @click.stop="toggleFavourite(organization)"
          >
            <ic-favourite
              v-if="!!organization.starred"
              class="text-bb-decorative-yellow"
            />
            <ic-favourite-outline
              v-else
              class="text-bb-disabled-buttons"
            />
          </div>
          <div class="flex flex-row truncate">
            <p class="font-bold truncate">
              {{ organization.name }}
            </p>
            <span
              v-if="organization.notification_count > 0"
              class="bg-bb-error rounded-full text-white px-2 max-w-4 h-full ml-2"
            >
              {{ organization.notification_count }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="card-item">
        <p class="text-bb-text-secondary">Clicks</p>
        <div class="flex flex-col">
          <p class="pNumLarge">
            {{ readableMetric(organization.clicks.current) }}
          </p>
          <div class="flex flex-row gap-2 justify-end pNum">
            <ic-arrow
              class="my-auto -mx-1"
              :class="[
                getClassBasedOnChange(organization.clicks.current, organization.clicks.compare).rotateClass,
                getClassBasedOnChange(organization.clicks.current, organization.clicks.compare).colorClass,
              ]"
              :size="16"
            />
            <p :class="getClassBasedOnChange(organization.clicks.current, organization.clicks.compare).colorClass">
              {{ calculatePercentageChange(organization.clicks.current, organization.clicks.compare) }}%
            </p>
          </div>
        </div>
      </div>
      <div class="card-item">
        <p class="text-bb-text-secondary">Conversions</p>
        <div class="flex flex-col">
          <p>{{ readableMetric(organization.conversions.current) }}</p>
          <div class="flex flex-row gap-2 justify-end pNum">
            <ic-arrow
              class="my-auto -mx-1"
              :class="[
                getClassBasedOnChange(organization.conversions.current, organization.conversions.compare).rotateClass,
                getClassBasedOnChange(organization.conversions.current, organization.conversions.compare).colorClass,
              ]"
              :size="16"
            />
            <p
              :class="
                getClassBasedOnChange(organization.conversions.current, organization.conversions.compare).colorClass
              "
            >
              {{ calculatePercentageChange(organization.conversions.current, organization.conversions.compare) }}%
            </p>
          </div>
        </div>
      </div>
      <div class="card-item">
        <p class="text-bb-text-secondary">Cost/Conv</p>
        <div
          v-if="!organization.mixed_currencies"
          class="flex flex-col"
        >
          <p>
            {{
              calculateCostPerConversion(
                organization.cost.current,
                organization.conversions.current,
                organization.currency,
              )
            }}
          </p>
          <div class="flex flex-row gap-2 justify-end pNum">
            <ic-arrow
              class="my-auto -mx-1"
              :class="[
                getClassBasedOnChange(
                  nanSafeDivision(organization.cost.current, organization.conversions.current),
                  nanSafeDivision(organization.cost.compare, organization.conversions.compare),
                ).rotateClass,
                getClassBasedOnChange(
                  nanSafeDivision(organization.cost.current, organization.conversions.current),
                  nanSafeDivision(organization.cost.compare, organization.conversions.compare),
                  'cost',
                ).colorClass,
              ]"
              :size="16"
            />
            <p
              :class="
                getClassBasedOnChange(
                  nanSafeDivision(organization.cost.current, organization.conversions.current),
                  nanSafeDivision(organization.cost.compare, organization.conversions.compare),
                  'cost',
                ).colorClass
              "
            >
              {{
                calculateConversionCostPercentageChange(
                  organization.cost.current,
                  organization.conversions.current,
                  organization.cost.compare,
                  organization.conversions.compare,
                )
              }}%
            </p>
          </div>
        </div>
        <div v-else>
          <p>N/A</p>
        </div>
      </div>
      <div class="card-item">
        <p class="text-bb-text-secondary">Cost</p>
        <div
          v-if="!organization.mixed_currencies"
          class="flex flex-col"
        >
          <p>
            {{ readableMetric(organization.cost.current) }}
          </p>
          <div class="flex flex-row gap-2 justify-end pNum">
            <ic-arrow
              class="my-auto -mx-1"
              :class="[
                getClassBasedOnChange(organization.cost.current, organization.cost.compare).rotateClass,
                getClassBasedOnChange(organization.cost.current, organization.cost.compare, 'cost').colorClass,
              ]"
              :size="16"
            />
            <p :class="getClassBasedOnChange(organization.cost.current, organization.cost.compare, 'cost').colorClass">
              {{ calculatePercentageChange(organization.cost.current, organization.cost.compare) }}%
            </p>
          </div>
        </div>
        <div
          v-else
          class="my-auto"
        >
          <p>N/A</p>
        </div>
      </div>
    </template>
    <template #expanded-content>
      <div v-if="isLoading">Loading sites...</div>
      <div v-if="organizationSiteRows.length > 0 && !isLoading">
        <div class="py-4 grid grid-rows-1 grid-cols-8 bg-neutral-0 rounded-lg text-bb-text-secondary md-gap-2 gap-15">
          <div class="flex pl-4 col-span-2">
            <p>Sites</p>
          </div>
          <p>Autopilot</p>
          <!-- <p>Channels</p> -->
          <p>Current/Target Spend</p>
          <p>Clicks</p>
          <p>Conversions</p>
          <p>Cost/Conv</p>
          <p>Cost</p>
        </div>
        <div
          v-for="row in organizationSiteRows"
          :key="row.id"
          class="w-full"
          @click="siteClick(row, organization)"
        >
          <div
            v-if="siteMetrics[row.id]"
            class="py-4 grid grid-rows-1 grid-cols-8 border-b hover:bg-neutral-50 md-gap-2 gap-15"
          >
            <div class="flex flex-col pl-4 my-auto col-span-2">
              <div class="flex flex-row gap-2">
                <p>{{ row.name }}</p>
                <span
                  v-if="siteMetrics[row.id].notification_count > 0"
                  class="bg-bb-error rounded-full text-white px-2 max-w-4 h-full ml-2"
                >
                  {{ siteMetrics[row.id].notification_count }}
                </span>
              </div>
            </div>
            <div>
              <div class="justify-center items-center gap-1 inline-flex">
                <div class="text-indigo-500 mt-4">
                  <IcStars :size="24"></IcStars>
                </div>
                <div class="mt-auto">{{ siteMetrics[row.id].autopilotStatus ? 'On' : 'Off' }}</div>
              </div>
            </div>
            <!-- <div>
              <div class="justify-end items-start gap-2.5 inline-flex">
                <div class="h-6 justify-center items-center gap-2 flex mt-4">
                  <IcGoogleSearchTag v-if="siteMetrics[row.id].available_integrations?.includes('google_ads')" />
                  <IcGoogleShoppingTag
                    v-if="siteMetrics[row.id].available_integrations?.includes('google_merchant_center')"
                  />
                </div>
              </div>
            </div> -->
            <div class="justify-start items-center gap-1 my-auto">
              <div v-if="siteMetrics[row.id].site_metric_target?.spend && siteMetrics[row.id].cost?.current">
                <div class="pNum">
                  <span>{{ readableMetric(siteMetrics[row.id].cost.current) }}</span
                  ><span>/</span>
                  <span>{{
                    readableMetric(siteMetrics[row.id].site_metric_target.spend, siteMetrics[row.id].currency)
                  }}</span>
                </div>

                <div class="mt-1">
                  <ProgressBar
                    :value="(siteMetrics[row.id].cost.current / siteMetrics[row.id].site_metric_target.spend) * 100"
                  ></ProgressBar>
                </div>
              </div>
              <div v-else>
                <p>N/A</p>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="flex flex-col">
                <p>{{ readableMetric(siteMetrics[row.id].clicks.current) }}</p>
                <div class="flex flex-row gap-2">
                  <ic-arrow
                    class="my-auto -mx-1"
                    :class="[
                      getClassBasedOnChange(siteMetrics[row.id].clicks.current, siteMetrics[row.id].clicks.compare)
                        .rotateClass,
                      getClassBasedOnChange(siteMetrics[row.id].clicks.current, siteMetrics[row.id].clicks.compare)
                        .colorClass,
                    ]"
                    :size="16"
                  />
                  <p
                    :class="
                      getClassBasedOnChange(siteMetrics[row.id].clicks.current, siteMetrics[row.id].clicks.compare)
                        .colorClass
                    "
                  >
                    {{
                      calculatePercentageChange(siteMetrics[row.id].clicks.current, siteMetrics[row.id].clicks.compare)
                    }}%
                  </p>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="flex flex-col">
                <p>{{ readableMetric(siteMetrics[row.id].conversions.current) }}</p>
                <div class="flex flex-row gap-2">
                  <ic-arrow
                    class="my-auto -mx-1"
                    :class="[
                      getClassBasedOnChange(
                        siteMetrics[row.id].conversions.current,
                        siteMetrics[row.id].conversions.compare,
                      ).rotateClass,
                      getClassBasedOnChange(
                        siteMetrics[row.id].conversions.current,
                        siteMetrics[row.id].conversions.compare,
                      ).colorClass,
                    ]"
                    :size="16"
                  />
                  <p
                    :class="
                      getClassBasedOnChange(
                        siteMetrics[row.id].conversions.current,
                        siteMetrics[row.id].conversions.compare,
                      ).colorClass
                    "
                  >
                    {{
                      calculatePercentageChange(
                        siteMetrics[row.id].conversions.current,
                        siteMetrics[row.id].conversions.compare,
                      )
                    }}%
                  </p>
                </div>
              </div>
            </div>
            <div
              v-if="!siteMetrics[row.id].mixed_currencies"
              class="flex flex-col"
            >
              <p>
                {{
                  calculateCostPerConversion(
                    siteMetrics[row.id].cost.current,
                    siteMetrics[row.id].conversions.current,
                    siteMetrics[row.id].currency,
                  )
                }}
              </p>
              <div class="flex flex-row gap-2">
                <ic-arrow
                  class="my-auto -mx-1"
                  :class="[
                    getClassBasedOnChange(
                      siteMetrics[row.id].cost.current / siteMetrics[row.id].conversions.current,
                      siteMetrics[row.id].cost.compare / siteMetrics[row.id].conversions.compare,
                      'cost',
                    ).rotateClass,
                    getClassBasedOnChange(
                      siteMetrics[row.id].cost.current / siteMetrics[row.id].conversions.current,
                      siteMetrics[row.id].cost.compare / siteMetrics[row.id].conversions.compare,
                      'cost',
                    ).colorClass,
                  ]"
                  :size="16"
                />
                <p
                  :class="
                    getClassBasedOnChange(
                      siteMetrics[row.id].cost.current / siteMetrics[row.id].conversions.current,
                      siteMetrics[row.id].cost.compare / siteMetrics[row.id].conversions.compare,
                      'cost',
                    ).colorClass
                  "
                >
                  {{
                    calculateConversionCostPercentageChange(
                      siteMetrics[row.id].cost.current,
                      siteMetrics[row.id].conversions.current,
                      siteMetrics[row.id].cost.compare,
                      siteMetrics[row.id].conversions.compare,
                    )
                  }}%
                </p>
              </div>
            </div>
            <div v-else>
              <p>N/A</p>
            </div>
            <div
              v-if="!siteMetrics[row.id].mixed_currencies"
              class="flex flex-col"
            >
              <p>{{ readableMetric(siteMetrics[row.id].cost.current, siteMetrics[row.id].currency) }}</p>
              <div class="flex flex-row gap-2">
                <ic-arrow
                  class="my-auto -mx-1"
                  :class="[
                    getClassBasedOnChange(siteMetrics[row.id].cost.current, siteMetrics[row.id].cost.compare, 'cost')
                      .rotateClass,
                    getClassBasedOnChange(siteMetrics[row.id].cost.current, siteMetrics[row.id].cost.compare, 'cost')
                      .colorClass,
                  ]"
                  :size="16"
                />
                <p
                  :class="
                    getClassBasedOnChange(siteMetrics[row.id].cost.current, siteMetrics[row.id].cost.compare, 'cost')
                      .colorClass
                  "
                >
                  {{ calculatePercentageChange(siteMetrics[row.id].cost.current, siteMetrics[row.id].cost.compare) }}%
                </p>
              </div>
            </div>
            <div v-else>
              <p>N/A</p>
            </div>
          </div>
          <div
            v-else
            class="grid grid-rows-1 grid-cols-5 lg:grid-cols-8 gap-2 p-4 border-b hover:bg-neutral-50"
          >
            <div class="flex flex-col lg:col-span-4 my-auto">
              <div class="flex flex-row gap-2">
                <p>{{ row.name }}</p>
              </div>
            </div>
            <div class="flex flex-col lg:col-span-4 my-auto">
              <div class="flex flex-row gap-2">
                <p>No data available. Set up a channel connection first.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="organizationSiteRows.length == 0 && !isLoading"
        class="grid grid-rows-1 grid-cols-5 lg:grid-cols-8 bg-neutral-0 rounded-lg p-4 gap-2"
      >
        <div class="flex flex-col lg:col-span-4">
          <p>No sites found in organization</p>
        </div>
      </div>
    </template>
  </bbBaseCard>
</template>

<script>
import bbBaseCard from '@/components/card/bbBaseCard.vue'
import IcFavouriteOutline from '@/components/icon/ic-favourite-outline.vue'
import IcFavourite from '@/components/icon/ic-favourite.vue'
import IcArrow from '@/components/icon/ic-arrow.vue'
import { mapActions, mapGetters } from 'vuex'
import Toast from '@/components/shared/Toast.vue'
import IcStars from '@/components/icon/ic-stars'
import IcGoogleSearchTag from '@/components/icon/brightbid/google-search-tag.vue'
import IcGoogleShoppingTag from '@/components/icon/brightbid/google-shopping-tag.vue'
import ProgressBar from '@/components/shared/ProgressBar.vue'
import {
  getClassBasedOnChange,
  readableMetric,
  calculateCostPerConversion,
  calculateConversionCostPercentageChange,
  calculatePercentageChange,
  nanSafeDivision,
} from '@/utils/compute-metrics-class'

export default {
  name: 'PortfolioOverview',
  components: {
    bbBaseCard,
    IcFavouriteOutline,
    IcFavourite,
    IcArrow,
    IcStars,
    IcGoogleSearchTag,
    IcGoogleShoppingTag,
    ProgressBar,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      organizationSiteRows: [],
      metricsLoaded: {},
    }
  },
  computed: {
    ...mapGetters({
      portfolioData: 'portfolio/portfolioData',
      siteMetrics: 'portfolio/siteMetrics',
      dates: 'portfolio/dates',
    }),
  },
  methods: {
    nanSafeDivision,
    ...mapActions({
      loadOrganizationSiteList: 'site/loadOrganizationSiteList',
      setSiteMetrics: 'portfolio/setSiteMetrics',
      loadSelectedSite: 'site/loadSelectedSite',
    }),
    async loadMetrics(organizationId) {
      if (this.metricsLoaded[organizationId]) {
        return
      }
      this.isLoading = true
      try {
        const { data: organizationSitesDetails } = await this.$http.get(`common/organization/${organizationId}/sites`)

        await Promise.all(
          organizationSitesDetails.map(async site => {
            if (site.site_integrations.length === 0) {
              await this.setSiteMetrics({ siteId: site.id, metrics: null })
            }
          }),
        )

        const { data: organizationSites } = await this.$http.get(`common/organization/${organizationId}/portfolio`, {
          params: {
            start_date: this.dates.startDate,
            end_date: this.dates.endDate,
            compare_start_date: this.dates.compareStartDate,
            compare_end_date: this.dates.compareEndDate,
          },
        })

        organizationSites.map(async site => {
          if (this.siteMetrics[site.id] === null && site.clicks.current === null && site.clicks.compare === null) {
            await this.setSiteMetrics({ siteId: site.id, metrics: null })
          } else {
            await this.setSiteMetrics({ siteId: site.id, metrics: site })
          }
        })

        this.organizationSiteRows = organizationSites
        this.metricsLoaded[organizationId] = true // Set data loaded flag
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to fetch the sites or site metrics',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    cardClicked(organizationID) {
      this.$router.push({
        name: 'organization:sites',
        params: {
          id: organizationID,
        },
      })
    },
    async updateOrganization(organization) {
      await this.$store.dispatch('portfolio/updateOrganization', {
        organizationId: organization.id,
        data: {
          starred: !organization.starred,
        },
      })
    },
    siteClick(site, organization) {
      const selectedSiteData = {
        label: site.name,
        value: site.id,
        site_url: site.url,
        site_integration: site.site_integrations,
        site_organization_name: organization.name,
        site_organization_id: organization.id,
      }
      this.loadSelectedSite(selectedSiteData)
      this.$router.push({ name: 'dashboard' })
    },
    async toggleFavourite(organization) {
      if (organization.starred) {
        await this.removeFromFavourite(organization)
      } else {
        await this.addAsFavourite(organization)
      }
    },
    async addAsFavourite(organization) {
      try {
        await this.$http.put(`/common/organization/${organization.id}/star`)
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: `${organization.name} added to favourites`,
            type: 'success',
          },
        })
        await this.updateOrganization(organization)
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to add organization to favourites',
            type: 'error',
          },
        })
      }
    },
    async removeFromFavourite(organization) {
      try {
        await this.$http.delete(`/common/organization/${organization.id}/star`)
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: `${organization.name} removed from favourites`,
            type: 'success',
          },
        })
        await this.updateOrganization(organization)
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to remove organization from favourites',
            type: 'error',
          },
        })
      }
    },
    getClassBasedOnChange(value, compareValue, metricType) {
      return getClassBasedOnChange(value, compareValue, metricType)
    },
    calculatePercentageChange(current, compare) {
      return calculatePercentageChange(current, compare)
    },
    readableMetric(value, currency) {
      return readableMetric(value, currency)
    },
    calculateConversionCostPercentageChange(currentCost, currentConversions, compareCost, compareConversions) {
      return calculateConversionCostPercentageChange(currentCost, currentConversions, compareCost, compareConversions)
    },
    calculateCostPerConversion(cost, conversions, currency) {
      return calculateCostPerConversion(cost, conversions, currency)
    },
  },
}
</script>

<style scoped lang="scss">
.card-item {
  @apply text-right flex flex-col;
  min-width: 128px;
}
</style>
