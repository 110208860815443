<template>
  <bbBaseCard
    class="bg-white mt-4"
    :checkbox="false"
    :curtain="false"
    @cardClick="selectSite(site)"
  >
    <template #first>
      <div class="flex-col justify-start items-start gap-3 inline-flex min-w-xxs">
        <div class="text-gray-500 text-xs font-medium leading-tight">Site</div>
        <div class="justify-start items-center gap-2 inline-flex">
          <div
            class="cursor-pointer"
            @click.stop="toggleFavourite(site)"
          >
            <IcFavouriteOutline
              v-if="!site.starred"
              class="text-bb-disabled-buttons"
            />
            <IcFavourite
              v-if="site.starred"
              class="text-bb-decorative-yellow"
            />
          </div>

          <div class="text-gray-700 text-sm font-bold">{{ site.name }}</div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="justify-between items-center md:flex">
        <div class="justify-start items-center gap-12 flex flex-wrap">
          <div class="flex-col w-20 justify-center items-end gap-1 md:inline-flex">
            <div class="text-gray-500 text-xs font-medium leading-tight">Autopilot</div>
            <div class="justify-center items-center gap-1 inline-flex">
              <div class="text-indigo-500 mt-4">
                <IcStars
                  :class="{ 'text-gray-500': !site.autopilotStatus }"
                  :size="24"
                />
              </div>
              <div class="mt-auto">{{ site.autopilotStatus ? 'On' : 'Off' }}</div>
            </div>
          </div>
          <div class="flex-col w-20 justify-center items-end gap-1 md:inline-flex">
            <div class="md: text-gray-500 text-xs font-medium leading-tight">Channels</div>
            <div class="justify-end items-start gap-2.5 inline-flex">
              <div class="h-6 justify-center items-center gap-2 flex mt-4">
                <IcGoogleSearchTag v-if="site.available_integrations?.includes('google_ads')" />
                <IcGoogleShoppingTag v-if="site.available_integrations?.includes('google_merchant_center')" />
              </div>
            </div>
          </div>
          <div class="flex-col w-35 justify-center items-end gap-1 md:inline-flex">
            <div class="md:text-left text-gray-500 text-xs font-medium leading-tight">Current/Target Spend</div>

            <div v-if="site.site_metric_target?.spend && site.cost?.current">
              <div class="justify-start items-center gap-1 mt-1">
                <div class="pNum">
                  <span>{{ readableMetric(site.cost.current) }}</span
                  ><span>/</span>
                  <span>{{ readableMetric(site.site_metric_target.spend, site.currency) }}</span>
                </div>
              </div>
              <div class="mt-1 flex justify-end">
                <ProgressBar :value="(site.cost.current / site.site_metric_target.spend) * 100"></ProgressBar>
              </div>
            </div>
            <div
              v-else
              class="mt-5"
            >
              <p>N/A</p>
            </div>
          </div>
          <div class="flex-col w-20 justify-center items-end gap-1 md:inline-flex">
            <div class="md:text-right text-gray-500 text-xs font-medium leading-tight">Clicks</div>
            <div class="md:text-right pNum">{{ readableMetric(site.clicks.current) }}</div>
            <div class="justify-end items-center gap-1 inline-flex">
              <ic-arrow
                class="my-auto -mx-1"
                :class="[
                  getClassBasedOnChange(site.clicks.current, site.clicks.compare).rotateClass,
                  getClassBasedOnChange(site.clicks.current, site.clicks.compare).colorClass,
                ]"
                :size="16"
              />
              <p :class="getClassBasedOnChange(site.clicks.current, site.clicks.compare).colorClass">
                {{ calculatePercentageChange(site.clicks.current, site.clicks.compare) }}%
              </p>
            </div>
          </div>
          <div class="flex-col w-20 justify-center items-end gap-1 md:inline-flex">
            <div class="md:text-right text-gray-500 text-xs font-medium leading-tight">Conversions</div>
            <div class="md:text-right pNum">{{ readableMetric(site.conversions.current) }}</div>
            <div class="justify-end items-center gap-1 inline-flex">
              <IcArrow
                class="my-auto -mx-1"
                :class="[
                  getClassBasedOnChange(site.conversions.current, site.conversions.compare).rotateClass,
                  getClassBasedOnChange(site.conversions.current, site.conversions.compare).colorClass,
                ]"
                :size="16"
              />

              <div
                :class="getClassBasedOnChange(site.conversions.current, site.conversions.compare).colorClass"
                class="text-xs pNum"
              >
                {{ calculatePercentageChange(site.conversions.current, site.conversions.compare) }}%
              </div>
            </div>
          </div>
          <div class="flex-col w-20 justify-center items-end gap-1 md:inline-flex">
            <div class="md:text-right text-gray-500 text-xs font-medium leading-tight">Cost/Conv</div>
            <div
              v-if="!site.mixed_currencies"
              class="flex-col gap-1 flex"
            >
              <div class="md:text-right pNum">
                {{ calculateCostPerConversion(site.cost.current, site.conversions.current, site.currency) }}
              </div>
              <div class="justify-end items-center gap-1 inline-flex">
                <IcArrow
                  class="my-auto -mx-1"
                  :class="[
                    getClassBasedOnChange(
                      site.cost.current / site.conversions.current,
                      site.cost.compare / site.conversions.compare,
                      'cost',
                    ).rotateClass,
                    getClassBasedOnChange(
                      site.cost.current / site.conversions.current,
                      site.cost.compare / site.conversions.compare,
                      'cost',
                    ).colorClass,
                  ]"
                  :size="16"
                />

                <div
                  :class="[
                    getClassBasedOnChange(
                      site.cost.current / site.conversions.current,
                      site.cost.compare / site.conversions.compare,
                      'cost',
                    ).colorClass,
                  ]"
                  class="text-xs pNum"
                >
                  {{
                    calculateConversionCostPercentageChange(
                      site.cost.current,
                      site.conversions.current,
                      site.cost.compare,
                      site.conversions.compare,
                    )
                  }}%
                </div>
              </div>
            </div>
            <div
              v-else
              class="my-auto"
            >
              <p>N/A</p>
            </div>
          </div>
          <div class="flex-col w-25 justify-center items-end gap-1 md:inline-flex">
            <div class="md:text-right text-gray-500 text-xs font-medium leading-tight">Cost</div>
            <div
              v-if="!site.mixed_currencies"
              class="flex-col gap-1 flex"
            >
              <div class="md:text-right pNum">
                {{ readableMetric(site.cost.current, site.currency) }}
              </div>
              <div class="justify-end items-center gap-1 inline-flex">
                <IcArrow
                  class="my-auto -mx-1"
                  :class="[
                    getClassBasedOnChange(site.cost.current, site.cost.compare, 'cost').rotateClass,
                    getClassBasedOnChange(site.cost.current, site.cost.compare, 'cost').colorClass,
                  ]"
                  :size="16"
                />

                <div
                  :class="[getClassBasedOnChange(site.cost.current, site.cost.compare, 'cost').colorClass]"
                  class="text-xs pNum"
                >
                  {{ calculatePercentageChange(site.cost.current, site.cost.compare) }}%
                </div>
              </div>
            </div>
            <div
              v-else
              class="my-auto"
            >
              <p>N/A</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </bbBaseCard>
</template>

<script>
import bbBaseCard from '@/components/card/bbBaseCard.vue'
import IcFavouriteOutline from '@/components/icon/ic-favourite-outline.vue'
import IcFavourite from '@/components/icon/ic-favourite.vue'
import IcGoogleShoppingTag from '@/components/icon/brightbid/google-shopping-tag.vue'
import ProgressBar from '@/components/shared/ProgressBar.vue'
import IcArrow from '@/components/icon/ic-arrow.vue'
import Toast from '@/components/shared/Toast.vue'
import {
  getClassBasedOnChange,
  calculatePercentageChange,
  readableMetric,
  calculateConversionCostPercentageChange,
  calculateCostPerConversion,
} from '@/utils/compute-metrics-class'
import IcStars from '@/components/icon/ic-stars'
import IcGoogleSearchTag from '@/components/icon/brightbid/google-search-tag.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    IcFavouriteOutline,
    IcFavourite,
    ProgressBar,
    IcArrow,
    IcGoogleShoppingTag,
    IcStars,
    IcGoogleSearchTag,
    bbBaseCard,
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
      isHovered: false,
      // Initialize caches for each method
      getClassBasedOnChangeCache: new Map(),
      calculatePercentageChangeCache: new Map(),
      readableMetricCache: new Map(),
      calculateConversionCostPercentageChangeCache: new Map(),
      calculateCostPerConversionCache: new Map(),
    }
  },
  computed: {
    ...mapState('organization', ['userOrganization']),
  },
  methods: {
    ...mapActions({
      loadSelectedSite: 'site/loadSelectedSite',
    }),
    memoizeMethod(cacheKey, cacheMap, fn) {
      if (cacheMap.has(cacheKey)) {
        return cacheMap.get(cacheKey)
      } else {
        const result = fn()
        cacheMap.set(cacheKey, result)
        return result
      }
    },
    getClassBasedOnChange(value, compareValue, metricType = null) {
      const cacheKey = `${value}-${compareValue}-${metricType}`
      return this.memoizeMethod(cacheKey, this.getClassBasedOnChangeCache, () =>
        getClassBasedOnChange(value, compareValue, metricType),
      )
    },
    calculatePercentageChange(current, compare) {
      const cacheKey = `${current}-${compare}`
      return this.memoizeMethod(cacheKey, this.calculatePercentageChangeCache, () =>
        calculatePercentageChange(current, compare),
      )
    },
    readableMetric(value, currency) {
      const cacheKey = `${value}-${currency}`
      return this.memoizeMethod(cacheKey, this.readableMetricCache, () => readableMetric(value, currency))
    },
    calculateConversionCostPercentageChange(currentCost, currentConversions, compareCost, compareConversions) {
      const cacheKey = `${currentCost}-${currentConversions}-${compareCost}-${compareConversions}`
      return this.memoizeMethod(cacheKey, this.calculateConversionCostPercentageChangeCache, () =>
        calculateConversionCostPercentageChange(currentCost, currentConversions, compareCost, compareConversions),
      )
    },
    calculateCostPerConversion(cost, conversions, currency) {
      const cacheKey = `${cost}-${conversions}-${currency}`
      return this.memoizeMethod(cacheKey, this.calculateCostPerConversionCache, () =>
        calculateCostPerConversion(cost, conversions, currency),
      )
    },
    async addAsFavourite(site) {
      try {
        await this.$http.put(`/common/site/${site.id}/star`)
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: `${site.name} added to favorites`,
            type: 'success',
          },
        })
        await this.updateSite(site)
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to add organization to favorites',
            type: 'error',
          },
        })
      }
    },
    async removeFromFavourite(site) {
      try {
        await this.$http.delete(`/common/site/${site.id}/star`)
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: `${site.name} removed from favorites`,
            type: 'success',
          },
        })
        await this.updateSite(site)
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to remove site from favorites',
            type: 'error',
          },
        })
      }
    },
    async toggleFavourite(site) {
      if (site.starred) {
        await this.removeFromFavourite(site)
      } else {
        await this.addAsFavourite(site)
      }
    },
    async updateSite(site) {
      await this.$store.dispatch('portfolio/updateSite', {
        siteId: site.id,
        data: {
          starred: !site.starred,
        },
      })
    },
    selectSite(site) {
      const selectedSite = this.userOrganization.flatMap(item => item.sites).find(orgSite => orgSite.id === site.id)

      const selectedSiteData = {
        site_integration: selectedSite.available_integrations,
        value: selectedSite.id,
        label: selectedSite.name,
        url: selectedSite.url,
        site_organization_name: this.userOrganization.find(org => org.id === selectedSite.organization_id).name,
        site_organization_id: selectedSite.organization_id,
      }
      this.loadSelectedSite(selectedSiteData)
      this.$router.push({ name: 'dashboard' })
    },
  },
}
</script>

<style scoped lang="scss">
.w-20 {
  width: 5rem;
}
.w-25 {
  width: 6.25rem;
}
</style>
